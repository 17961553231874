.button {
  display: inline-block;
  margin-bottom: 60px;
  padding: 22px 40px 20px;
  text-decoration: none;
  font-size: 1.125em;
  color: #fff;
  text-shadow: 0 1px 3px rgba(0,0,0,0.4);
  background-image: -webkit-linear-gradient(#96c845, #8bb051);
  background-image: linear-gradient(#96c845, #8bb051);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.28);
  border-radius: 8px;

  &:hover,
  &:focus {
    color: #fff;
    background-image: -webkit-linear-gradient(#8ebc41, #83a74c);
    background-image: linear-gradient(#8ebc41, #83a74c);
  }

  &--main {
    text-align: center;
  }
}
