.pagination {
  @include clearfix;

  &__item {
    padding: 6px 20px 4px;
    font-size: 1.125em;
    text-decoration: none;
    color: #fff;
    background-image: -webkit-linear-gradient(#96c845, #8bb051);
    background-image: linear-gradient(#96c845, #8bb051);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    border-radius: 18px;

    &--new {
      float: left;
    }

    &--old {
      float: right;
    }

    &:hover,
    &:focus {
      color: #fff;
      background-image: -webkit-linear-gradient(#8ebc41, #83a74c);
      background-image: linear-gradient(#8ebc41, #83a74c);
    }
  }
}
