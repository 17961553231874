%headline {
  margin-top: 0;
  color: #90b03e;
}

.primary-headline {
  @extend %headline;
  font-size: 1.875em;
  line-height: 1.1;
  text-align: center;

  a {
    color: #90b03e;
    text-decoration: none;

    &:hover,
    &:focus {
      color: darken(#90b03e, 5%);
    }
  }
}

.secondary-headline{
  @extend %headline;
  font-size: 1.25em;
  line-height: 1.1;
}

.logo {
  margin: 0;
  text-align: center;

  span {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
  }
}

.tagline {
  padding-top: 45px;
  font-size: 1.5em;
  font-weight: bold;
}

.details {
  text-align: center;
}

.avatar {
  border: 4px solid #90b03e;
  border-radius: 100%;
}
