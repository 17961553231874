body {
  padding: 60px 0 60px;
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1.4;
  color: #fff;
  background: #5e7a39;

  @media screen and (max-width: 31.25em) {
    text-align: center;
  }
}

a {
  color: #90b03e;

  &:hover,
  &:focus {
    color: darken(#90b03e, 5%);
  }
}

img,
object,
embed {
  max-width: 100%;
}

hr {
  margin: 50px 0;
  border-top: 1px solid #90b03e;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  height: 2px;
  background-color: #90b03e;
}
