.media {
  overflow: hidden;
}

.media__body {
  overflow: hidden;
}

.media__body p:last-child {
  margin-bottom: 0;
}

.media__obj {
  float: left;
  // padding-top: 50px;
  margin-right: 40px;
  display: block;
}

@media screen and (max-width: 31.25em) {
  .media__obj {
    float: none;
    padding-top: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
