.l-container {
  margin: 0 auto;
  max-width: 38.75em;
  width: 90%;
}

.divider {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 3px solid #90b03e;
}

.two-col {
  overflow: hidden;
}

.col {
  margin-left: 3.2258064516%;
  float: left;
  width: 48.387096774%;

  &:first-child {
    margin-left: 0;
  }

  @media screen and (max-width: 27.5em) {
    float: none;
    margin-left: 0;
    margin-bottom: 40px;
    width: 100%;
  }
}

.cta {
  text-align: center;
}
